<template>
  <v-menu bottom right>
    <template v-slot:activator="{ on }">
      <v-btn outlined v-on="on">
        <span v-if="!$vuetify.breakpoint.smAndDown"
          >{{ report.assCount }}-{{
            $options.reportTypes[
              $options.reportTypes.findIndex(r => r.id === report.type)
            ].name
          }}</span
        >
        <v-icon right>fas fa-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group
        :value="$options.reportTypes.findIndex(r => r.id === report.type)"
      >
        <v-list-item
          v-for="{ name, id } in new Array(...$options.reportTypes)"
          :key="id"
          @click="typeChanged(id)"
        >
          <v-list-item-title>{{ name }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { reportTypes } from "@/globals";
import { mapGetters } from "vuex";
export default {
  reportTypes,
  name: "report_type_dropdown",
  computed: {
    ...mapGetters({ report: "report" })
  },
  methods: {
    typeChanged(index) {
      this.$store.commit("reportIndex", 0);
      this.$store.commit("reportType", index);
    }
  }
};
</script>

<style scoped></style>
